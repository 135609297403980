html,body{
  height:100%;
   min-height:100%; 
  }
  
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  .pinTipBody
  {
    color: black !important;
  }
  
  #headerRight {
    background: #9D9D9D;
    border: medium none;
    height: 50px;
    margin: 0;
    min-width: 960px;
    padding: 0;
    width: 100%;
  }
  
  .main-header .app-name {
    padding: 11px 15px 8px 10px;
  }
  
  .bolder-italic-new-roman{
    font-weight: bolder;
    font-style: italic;
    font-family: "Times New Roman", Times, serif;
  }
  
  .form-inline .form-group.employee-autocomplete .dropdown {
    width: 300px;
  }
  
  .pre-approved-status {
    color: #65819E;
    font-weight: bold;
  }
     
  .hide
  {
    display:none ; 
  }
  
  .visible
  {
    display:inline ; 
  }
  