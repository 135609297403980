.nmPageBodyWhite
{
	background-color: #F3F3F3;
}
.nmPageBodyWhite .nmPageContainer
{
	color: #333;
}
.nmPageBodyWhite .nmPageHeader
{
	background-color: #FFFFFF;	
}
.nmPageBodyWhite .nmPageContent 
{
    background-color: #F3F3F3;
}
.nmPageBodyWhite .nmContent
{
	/*background-color: #FFFFFF;*/	
}
.nmPageBodyWhite .box
{
	background: #ffffff;
}
.nmPageBodyWhite .box-header
{
	color: #444;
}
.nmPageBodyWhite .nmPageHeader .nmLogo 
{
    background-color: #8b5c7e;
    color: #ffffff;
    border-bottom: 0px solid transparent;
}
.nmPageBodyWhite .nmPageHeader .nmPageHeaderContent 
{
    background-color: #737373;
}
.nmPageBodyWhite .nmHeaderRight 
{
    background: #9D9D9D;
}
.nmPageBodyWhite .nmPageHeader .appName > h1 
{
    color: #fff;
}
.nmPageBodyWhite .nmSidebarToggler
{
	color: #fff;
}
.nmPageBodyWhite .nmSidebarToggler:hover
{
	background-color: #737373;
	color: #fff;
}
.nmPageBodyWhite .nmPageLogo .nmMainTitle 
{
    color: #2B2B2B;
}
.nmPageBodyWhite .nmHeaderRight>.nmAppName > h1 
{
    color: #fff;
}
.nmPageBodyWhite .nmHeaderRight>.nmAppDetail>.welcomeMessage 
{
    color: #fff;
}
.nmPageBodyWhite .nmPageLogo small 
{
  	color:#808080;
}
.nmPageBodyWhite .nmPageFooter 
{
    background: #fff;
    color: #444;
    border-top: 1px solid #d2d6de;
}
.nmPageBodyWhite .form-group.has-error .form-control 
{
    border-color: #dd4b39 !important;
    box-shadow: none;
}
/*************Horizontal Nav ***************/
.nmPageBodyWhite .navbar-nav>li>a 
{
    color: #fff;
}
.nmPageBodyWhite .nav > li > a:hover, .nav > li > a:active, .nav > li > a:focus {
    color: #444;
    background: #f7f7f7;
}
.nmPageBodyWhite .main-header .logo {
    background-color: #8b5c7e;
    color: #ffffff;
    border-bottom: 0px solid transparent;
}
.nmPageBodyWhite .main-header .navbar-brand {
  color: #fff;
}
.nmPageBodyWhite .main-header .navbar {
    background-color: #737373;
}
.nmPageBodyWhite .main-header .app-name > h1 {
    color: #fff;
}
.nmPageBodyWhite .main-header .welcom-message {
    color: #fff;
}
.nmPageBodyWhite .main-header .navbar .sidebar-toggle {
    color: #fff;
}
.nmPageBodyWhite .main-header .navbar .sidebar-toggle:hover {
    background-color: #737373;
}
/*************End of Horizontal Nav ***************/
/*************Modal Component ***************/
.nmPageBodyWhite .text-ns-red {
    color: #CA2420 !important;
}
.nmPageBodyWhite .checkbox,
.nmPageBodyWhite .radio 
{
  color: #000000;
}
/*************End of Modal Component ***************/
