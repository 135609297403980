/* taken from https://use.typekit.net/zlj2zcu.css */

@import url("https://p.typekit.net/p.css?s=1&k=zlj2zcu&ht=tk&f=27707&a=19392654&app=typekit&e=css");

@font-face {
font-family:"acier-bat-gris";
src:url("https://use.typekit.net/af/b2b981/00000000000000007735dfaf/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/b2b981/00000000000000007735dfaf/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/b2b981/00000000000000007735dfaf/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;
}

.tk-acier-bat-gris { font-family: "acier-bat-gris",sans-serif; }

/* end of https://use.typekit.net/zlj2zcu.css */

.textAnimation
{
	font-family: acier-bat-gris, sans-serif;
  	font-weight: 400;
  	font-size: 75px;
  	line-height: 1;
}