.nmPageBodyBlack
{
	background-color: #000000;
}
.nmPageBodyBlack .nmPageContainer
{
	color: #FFFFFF;
}
.nmPageBodyBlack .nmPageHeader
{
	background-color: #000000;	
}
.nmPageBodyBlack .nmPageContent 
{
    background-color: #000000;
}
.nmPageBodyBlack .nmContent
{
	background-color: #000000;	
}
.nmPageBodyBlack .box
{
	background: #000000;
}
.nmPageBodyBlack .box-header
{
	color: #FFFFFF;
}
.nmPageBodyBlack .select
{
	 background-color: #000000;
}
.nmPageBodyBlack .nmPageHeader .nmLogo 
{
    background-color: #8b5c7e;
    color: #FFFFFF;
    border-bottom: 0px solid transparent;
}
.nmPageBodyBlack .nmPageHeader .nmPageHeaderContent 
{
    background-color: #737373;
}
.nmPageBodyBlack .nmHeaderRight 
{
    background: #9D9D9D;
}

.nmPageBodyBlack .nmHeaderRight 
{
    background: #9D9D9D;
}
.nmPageBodyBlack .nmPageHeader .appName > h1 
{
    color: #000000;
}
.nmPageBodyBlack .nmSidebarToggler
{
	color: #FFFFFF;
}
.nmPageBodyBlack .nmSidebarToggler:hover
{
	background-color: #FFFFFF;
	color: #000000;
}
.nmPageBodyBlack .nmPageLogo .nmMainTitle 
{
	color:#f6f6f6;
}
.nmPageBodyBlack .nmHeaderRight>.nmAppName > h1 
{
    color: #FFFFFF;
}
.nmPageBodyBlack .nmHeaderRight>.nmAppDetail>.welcomeMessage 
{
    color: #FFFFFF;
}
.nmPageBodyBlack .nmPageLogo small 
{
  	color:#eee;
}
.nmPageBodyBlack .demo-panel-title 
{
  	color: #eee;
}
.nmPageBodyBlack .nmPageFooter 
{
    background: #000000;
    color: #FFFFFF;
    border-top: 1px solid #d2d6de;
}
.nmPageBodyBlack .form-group.has-error .form-control 
{
    border-color: #dd4b39 !important;
    box-shadow: none;
}

/*************Horizontal Nav ***************/
.nmPageBodyBlack .navbar-nav>li>a 
{
    color: #000000;
}
.nmPageBodyBlack .nav > li > a:hover, .nav > li > a:active, .nav > li > a:focus {
    color: #444;
    background: #f7f7f7;
}
.nmPageBodyBlack .main-header .logo {
    background-color: #8b5c7e;
    color: #000000;
    border-bottom: 0px solid transparent;
}
.nmPageBodyBlack .main-header .navbar-brand {
  color: #000000;
}
.nmPageBodyBlack .main-header .navbar {
    background-color: #FFFFFF;
}
.nmPageBodyBlack .main-header .app-name > h1 {
    color: #000000;
}
.nmPageBodyBlack .main-header .welcom-message {
    color: #000000;
}
.nmPageBodyBlack .main-header .navbar .sidebar-toggle {
    color: #000000;
}
.nmPageBodyBlack .main-header .navbar .sidebar-toggle:hover {
    background-color: #FFFFFF;
}
/*************End of Horizontal Nav ***************/
/*************Modal Component ***************/
.nmPageBodyBlack .text-ns-red {
    color: #CA2420 !important;
}
/*************End of Modal Component ***************/
.nmPageBodyBlack .checkbox,
.nmPageBodyBlack .radio 
{
  color: #FFFFFF;
}