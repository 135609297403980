.loader{ background-color: rgba(0,0,0,0.95); height: 100%; width: 100%; position: fixed; z-index: 999; margin-top: 0px; top: 0px;}
.loader-centered { position: absolute; left: 50%; top: 50%; height: 200px; width: 200px; margin-top: -100px; margin-left: -100px;}
.object{ width: 50px; height: 50px; background-color: rgba(255,255,255,0); margin-right: auto; margin-left: auto; border: 4px solid #FFF; left: 73px; top: 73px; position: absolute;}

.square-one {-webkit-animation: first_object_animate 1s infinite ease-in-out; animation: first_object_animate 1s infinite ease-in-out;} 
.square-two {-webkit-animation: second_object 1s forwards, second_object_animate 1s infinite ease-in-out; animation: second_object 1s forwards, second_object_animate 1s infinite ease-in-out; }    
.square-three {-webkit-animation: third_object 1s forwards, third_object_animate 1s infinite ease-in-out; animation: third_object 1s forwards, third_object_animate 1s infinite ease-in-out; }       

@-webkit-keyframes second_object {
	100% { width: 100px; height:100px; left: 48px; top: 48px; }
}		
@keyframes second_object {
	100% { width: 100px; height:100px; left: 48px; top: 48px; }
}
@-webkit-keyframes third_object {
	100% { width: 150px; height:150px; left: 23px; top: 23px;}
}		
@keyframes third_object {
	100% { width: 150px; height:150px; left: 23px; top: 23px;}
}

@-webkit-keyframes first_object_animate {
  0% { -webkit-transform: perspective(100px); }
  50% { -webkit-transform: perspective(100px) rotateY(-180deg); }
  100% { -webkit-transform: perspective(100px) rotateY(-180deg) rotateX(-180deg); }
}

@keyframes first_object_animate {
	0% { 
		transform: perspective(100px) rotateX(0deg) rotateY(0deg);
		-webkit-transform: perspective(100px) rotateX(0deg) rotateY(0deg); 
	} 50% { 
		transform: perspective(100px) rotateX(-180deg) rotateY(0deg);
		-webkit-transform: perspective(100px) rotateX(-180deg) rotateY(0deg) ;
	} 100% { 
		transform: perspective(100px) rotateX(-180deg) rotateY(-180deg);
		-webkit-transform: perspective(100px) rotateX(-180deg) rotateY(-180deg);
	}
}

@-webkit-keyframes second_object_animate {
	0% { -webkit-transform: perspective(200px); }
	50% { -webkit-transform: perspective(200px) rotateY(180deg); }
	100% { -webkit-transform: perspective(200px) rotateY(180deg) rotateX(180deg); }
}	


@keyframes second_object_animate {
	0% { 
		transform: perspective(200px) rotateX(0deg) rotateY(0deg);
		-webkit-transform: perspective(200px) rotateX(0deg) rotateY(0deg); 
	} 50% { 
		transform: perspective(200px) rotateX(180deg) rotateY(0deg);
		-webkit-transform: perspective(200px) rotateX(180deg) rotateY(0deg) ;
	} 100% { 
		transform: perspective(200px) rotateX(180deg) rotateY(180deg);
		-webkit-transform: perspective(200px) rotateX(180deg) rotateY(180deg);
	}
}

@-webkit-keyframes third_object_animate {
	0% { -webkit-transform: perspective(300px); }
	50% { -webkit-transform: perspective(300px) rotateY(-180deg); }
	100% { -webkit-transform: perspective(300px) rotateY(-180deg) rotateX(-180deg); }
}

@keyframes third_object_animate {
	0% { 
		transform: perspective(300px) rotateX(0deg) rotateY(0deg);
		-webkit-transform: perspective(300px) rotateX(0deg) rotateY(0deg); 
	} 50% { 
		transform: perspective(300px) rotateX(-180deg) rotateY(0deg);
		-webkit-transform: perspective(300px) rotateX(-180deg) rotateY(0deg) ;
	} 100% { 
		transform: perspective(300px) rotateX(-180deg) rotateY(-180deg);
		-webkit-transform: perspective(300px) rotateX(-180deg) rotateY(-180deg);
	}
}